<template>
	<main>
		<ApplyBaseTemplate
			:interview-started="interviewStarted"
			:interview-finished="interviewFinished"
			:already-applied="alreadyApplied"
			:first-msg="firstMsg"
			:answered-msgs="answeredMsgs"
			:question-stats="questionStats"
			:guest-login="guestLogin"
			:match="match"
			:err="err"
			:interview-mode="mode"
			:match-obj="match"
			:allow-cv-parsing="allowCvParsing"
			:hide-tabs="true"
			:audience-id="audienceId"
			:job-nr-or-id="companyId"
			@update-allow-cv-parsing="allowCvParsing = $event"
			@update-match-obj="match = $event"
			@restart-interview="callRestartInterview"
			@interview-done="callFinishInterview"
		>
			<Tab id="company-scroll-container" name="Unternehmen">
				<CompanyPageWrapper
					v-if="company && company._id"
					:company-id="company._id"
					:load-instantly="false"
				/>
			</Tab>
			<template v-if="!isInsidePageOpen" #heading>
				<div class="border-solid border-b pb-2 border-color-grey-light">
					<div class="flex mx-2 mt-2">
						<div
							v-if="
								match &&
								match.relation &&
								match.relation.obj &&
								match.relation.obj.logos &&
								match.relation.obj.logos.medium
							"
							class="w-3/12 mr-3 flex items-center"
							style="max-width: 60px; max-height: 60px"
						>
							<NuxtImg
								:src="match.relation.obj.logos.medium"
								:alt="`Firmenlogo ${match.relation.obj.name}`"
								class="max-h-full max-w-full mx-auto"
							/>
						</div>
						<div
							class="flex flex-col justify-center"
							:class="
								match &&
								match.relation &&
								match.relation.obj &&
								match.relation.obj.logos &&
								match.relation.obj.logos.medium
									? 'w-8/12'
									: 'w-11/12'
							"
						>
							<p class="mb-0 text-color-main truncate text-sm">
								{{ match && match.relation && match.relation.obj && match.relation.obj.name }}
							</p>
							<p class="mb-0 truncate text-sm">Initiativbewerbung</p>
						</div>
						<div class="relative w-full flex items-center">
							<HokIcon
								name="icon:close-slim"
								:size="7"
								pointer
								class="absolute right-0"
								@click="$modal.show('preventClosing')"
							/>
						</div>
					</div>
				</div>
			</template>
		</ApplyBaseTemplate>
	</main>
</template>
<script lang="ts">
import Tab from '@hokify/shared-components-nuxt3/lib/components/Tab.vue';
import hokifyApply, {
	ApplyMode,
	type IPWAInterviewQuestion
} from '@hokify/pwa-core-nuxt3/helpers/apply';
import type {
	APIMatchRelation,
	APIObjectType,
	APITypeObjectId,
	IAPICompany,
	IAPIMatchForJobSeeker,
	IAPIMatchForJobSeekerListEntry,
	IInterviewQuestion
} from '@hokify/common';
import { NotFoundError } from '@hokify/pwa-core-nuxt3/errors/NotFoundError';
import {
	isNotAuthenticatedError,
	NotAuthenticatedError
} from '@hokify/pwa-core-nuxt3/errors/NotAuthenticatedError';
import type { PropType } from 'vue';
import { shareJob } from '@hokify/pwa-core-nuxt3/helpers/sharejob';
import { mapStores } from 'pinia';
import {
	defineNuxtComponent,
	definePageMeta,
	tryUseNuxtApp,
	useRoute,
	useRelationsStore,
	useSeoMeta,
	createError
} from '#imports';
import { useCompanyStore } from '@/stores/company';
import { useInterviewStore } from '@/stores/interview';
import type { IStartInterviewResultDTO } from '@/stores/interview';
import ApplyBaseTemplate from '@/components/user/application/ApplyBaseTemplate.vue'; // from here comes the template
import CompanyPageWrapper from '@/components/user/CompanyPageWrapper.vue';
import { useMatchesStore } from '@/stores/matches';
import { useLoginStore } from '@/stores/login';

export default defineNuxtComponent({
	name: 'ApplyCompany',
	setup() {
		definePageMeta({
			layout: 'apply',
			path: '/apply-company/:companyId?',
			alias: '/apply-company-fb/:companyId?'
		});
	},
	components: {
		ApplyBaseTemplate,
		Tab,
		CompanyPageWrapper
	},
	async asyncData({ $pinia }) {
		const { params } = useRoute();
		const companyStore = useCompanyStore($pinia);
		const matchesStore = useMatchesStore($pinia);
		const interviewStore = useInterviewStore($pinia);
		const relationsStore = useRelationsStore($pinia);
		let company: IAPICompany | undefined;
		let companyId;
		let audienceId;

		if (params?.companyId) {
			companyId = params.companyId;
		} else if (relationsStore.numberOrId) {
			companyId = relationsStore.numberOrId.idOrNr;
		}

		if (params?.audienceId) {
			audienceId = params.audienceId;
		} else if (relationsStore.numberOrId) {
			audienceId = relationsStore.numberOrId.audienceId;
		}
		try {
			// saves job and starts interview
			const interviewRes: IStartInterviewResultDTO = await interviewStore.startInterview({
				relation: { type: 'company', obj: (company && company._id) || companyId },
				audienceId
			});

			const matchObj = interviewRes && interviewRes.success && interviewRes.match;

			// save in store
			if (matchObj) {
				matchesStore.saveMatch(matchObj);
			}

			const {
				success,
				next,
				finish,
				cntOpenQuestions,
				totalQuestions,
				answeredQuestions,
				allowCvParsing
			} = interviewRes;

			let interviewStarted = false;
			let interviewFinished = false;
			let questionStats;

			if (success) {
				if (next) {
					interviewStarted = true;
				} else if (finish) {
					interviewFinished = true;
				}
				questionStats = {
					open: cntOpenQuestions,
					total: totalQuestions
				};
			}

			if (!company && matchObj && matchObj.relation.type === 'company' && matchObj.relation.obj) {
				company = matchObj.relation.obj;
			} else if (!company) {
				// we have no relation obj yet, retrieve relation obj seperately
				const res = await companyStore.getCompany(companyId);
				company = res.company;
			}

			if (!company) {
				throw new NotFoundError('Company existiert nicht');
			}

			return {
				company,
				match: matchObj || { relation: { type: 'company', obj: company } },
				interviewStarted,
				interviewFinished,
				answeredMsgs: answeredQuestions || [],
				firstMsg: next || null,
				questionStats,
				alreadyApplied: false,
				allowCvParsing,
				err: undefined
			};
		} catch (err: any) {
			const errorCode = err?.response?.data?.code;
			const errorStatus = err.response?.status;

			// fetch the current company (checks also if live + gets title and stuff)
			if (!company) {
				const res = await companyStore.getCompany(companyId).catch(retrieveErr => {
					console.error(retrieveErr);
					// ignore errors here
				});
				if (res?.company) {
					company = res.company;
				}
			}

			if (!company) {
				throw createError({ statusMessage: 'Diese Seite existiert nicht.', statusCode: 404 });
			}

			// not logged in yet, login required
			if (isNotAuthenticatedError(err)) {
				const firstMsg = hokifyApply.startLoginOrSignUp();

				return {
					company,
					guestLogin: !firstMsg,
					interviewStarted: true,
					interviewFinished: false,
					firstMsg,
					answeredMsgs: [],
					match: { relation: { type: 'company', obj: company } },
					// set a default value, to be sure stats bar is always shown
					questionStats: {
						open: 100,
						total: 100
					},
					alreadyApplied: false,
					err
				};
			}

			// already applied
			if (errorCode === 'ALREADY_APPLIED') {
				return {
					company,
					interviewStarted: false,
					interviewFinished: true,
					answeredMsgs: [],
					match: { relation: { type: 'company', obj: company } },
					alreadyApplied: true,
					err
				};
			}

			// generic / other error
			if (import.meta.client) {
				console.error('start-interview ERROR', err?.response, {
					errorStatus,
					errorCode,
					code: err.code,
					statusCode: err.statusCode,
					instanceOfNotAuthenticatedError: err instanceof NotAuthenticatedError,
					typeofErrorNow: typeof err,
					isNotAuthenticatedError: isNotAuthenticatedError(err)
				});
			}

			const nuxtApp = tryUseNuxtApp();
			if (nuxtApp?.$sentry) {
				nuxtApp.$sentry.withScope(scope => {
					scope.setExtra('errorHandler', '/apply-company/[companyId]');
					if (err instanceof Error) {
						nuxtApp.$sentry.captureException(err);
					} else {
						scope.setExtra('err', err);
						nuxtApp.$sentry.captureMessage(
							`errorHandler${typeof err.toString === 'function' ? ` ${err.toString()}` : ''}`
						);
					}
				});
			}

			throw createError({
				statusCode: errorStatus,
				message: 'Eine Bewerbung für diese Firma ist momentan nicht möglich.',
				fatal: true
			});
		}
	},
	data() {
		const gncCalculation = undefined as {} | undefined;
		const answeredMsgs: IPWAInterviewQuestion[] = [];
		const firstMsg = false as IInterviewQuestion | boolean | undefined;
		const err = {} as any;
		const match = undefined as undefined | IAPIMatchForJobSeekerListEntry | IAPIMatchForJobSeeker;
		const company = undefined as undefined | IAPICompany;
		const interviewStarted = false;
		const interviewFinished = false;
		const alreadyApplied = false;
		const allowCvParsing = false;
		const restartedInterview = false;
		const guestLogin = false;
		const questionStats = {};
		const viewState: { mode: 'typeform' | 'match-overview' } = { mode: 'typeform' };

		return {
			gncCalculation,
			company,
			match,
			interviewStarted,
			interviewFinished,
			alreadyApplied,
			allowCvParsing,
			restartedInterview,
			err,
			guestLogin,
			firstMsg,
			answeredMsgs,
			questionStats,
			mode: ApplyMode.ApplyCompany,
			shareJob,
			viewState
		};
	},
	created() {
		const title = `Initiativbewerbung bei ${this.company?.name || 'einer Firma'} | hokify`;
		const description = `Versende jetzt deine Initiativbewerbung mit wenigen Schritten und übermittle sie direkt an
		  ${this.company?.name || 'die Firma'}`;
		const image = this.company?.logos?.large || '';
		useSeoMeta({
			title,
			description,
			ogTitle: title,
			ogImage: image,
			ogDescription: description
		});
	},
	computed: {
		isInsidePageOpen() {
			return this.$page?.isOpen;
		},
		companyId(): string {
			return this.company?._id || '';
		},
		...mapStores(useInterviewStore, useLoginStore, useMatchesStore)
	},
	mounted() {
		if (this.company) {
			const queryParams =
				(window.location.search && new URLSearchParams(window.location.search)) || undefined;

			// tracking for remarketing
			const country = this.company.location?.[0].countryCode
				? this.company.location?.[0].countryCode.replace(/\W/g, '').toUpperCase()
				: undefined;

			// set category via 1. prop (clickSource)  2. query param 3. default value
			this.$trackUserEvent?.('start_application', {
				matchId: this.match?._id,
				company: {
					name: this.company.name,
					_id: this.company._id,
					jobfields: this.company.fields?.map(f => f.name),
					country,
					city: this.company.location?.[0]?.city || undefined,
					audienceId: this.audienceId
				},
				relationId: this.company._id,
				item_type: 'company',
				category: this.clickSource || queryParams?.get('cs') || 'direct-link', // cs .. click source
				is_logged_in: this.loginStore.loggedIn ? 1 : 0
			});
		}
		// show modal, before closing insidePage
		this.updateGoBackHandlerForCurrentPage();
	},
	methods: {
		callRestartInterview() {
			if (this.company) {
				const relation: APIMatchRelation = {
					type: 'company',
					obj: this.company
				};
				this.restartInterview(relation);
			}
		},
		callFinishInterview() {
			if (this.company) {
				const relation: APIMatchRelation = {
					type: 'company',
					obj: this.company
				};
				this.finishInterview(relation);
			}
		},
		async restartInterview(relation: APIMatchRelation) {
			try {
				const interviewRes: IStartInterviewResultDTO = await this.interviewStore.startInterview({
					relation,
					resetSkippedQuestions: true,
					audienceId: this.audienceId
				});
				if (interviewRes) {
					const {
						success,
						next,
						cntOpenQuestions,
						totalQuestions,
						answeredQuestions,
						allowCvParsing
					} = interviewRes;
					if (success) {
						this.questionStats = {
							open: cntOpenQuestions,
							total: totalQuestions
						};
					}
					this.answeredMsgs = answeredQuestions || [];
					this.firstMsg = next;

					// reset all params to redirect to typeform again
					this.interviewFinished = false;
					this.interviewStarted = true;
					this.alreadyApplied = false;

					// reset view mode
					this.viewState.mode = 'typeform';

					// additionally remember, we have restarted interview
					this.restartedInterview = true;
					this.allowCvParsing = allowCvParsing;
				}
			} catch (err) {
				console.warn(err);
			}
		},
		async finishInterview(relation: APIMatchRelation) {
			// if the interview is already finished, there is no need anymore to block leaving the interview process via a modal
			this.updateGoBackHandlerForCurrentPage(true);

			// after finishing the interview, make sure you have a proper match object
			// by now this might be only a "fake" match obj with job/company inside, so you can start the interview
			// but at this point (for showing the matchoverview) you need a proper match object, to show matchquestions
			// also get new matchObj if interview was restarted, to make sure all questions are up to date
			// grab correct match obj
			if (!this.match?._id || this.restartedInterview) {
				this.match = this.matchesStore.getMatchByRelation(relation);

				if (!this.match) {
					const err = new Error(`match not found for relation ${relation?.obj?._id}`);
					// this can happen for active sourcing jobs for example
					if (relation) {
						console.error(err);

						const interviewRes: IStartInterviewResultDTO = await this.interviewStore.startInterview(
							{
								relation,
								audienceId: this.audienceId
							}
						);

						const matchObj = interviewRes && interviewRes.success && interviewRes.match;
						this.match = matchObj || ({ relation } as IAPIMatchForJobSeeker);
					} else {
						throw err;
					}
				}
			}

			this.interviewFinished = true;
			this.interviewStarted = false;

			// ui components have a different style for match-overview
			this.viewState.mode = 'match-overview';

			if (import.meta.client) {
				this.$nextTick(() => {
					this.$page.scrollToTop();
				});
			}
		},
		updateGoBackHandlerForCurrentPage(forceClosing = false) {
			// show modal, before closing insidePage
			this.$page.setGoBackHandlerForCurrentPage(() => {
				if (forceClosing) {
					return false;
				}
				this.$modal.show('preventClosing');
				this.$trackUserEvent?.('application_cancel_show_modal', {});
				return true;
			});
		}
	},
	props: {
		clickSource: { type: String, required: false, default: '' },
		audienceId: {
			type: String as PropType<APITypeObjectId<APIObjectType.CompanyAudience>>,
			required: false,
			default: ''
		}
	}
});
</script>
